<template>
  <v-container
    id="login-container"
    fluid
    class="d-flex align-center justify-center"
  >
    <v-row class="d-flex align-center">
      <v-col
        offset="1"
        offset-sm="2"
        offset-md="2"
        offset-lg="3"
        cols="10"
        sm="8"
        md="8"
        lg="6"
      >
        <ValidationObserver
          id="validation-observer"
          ref="observer"
          v-slot="{ handleSubmit }"
        >
          <v-form
            id="login-form"
            ref="form"
            role="loginForm"
            @submit.prevent="handleSubmit(login)"
          >
            <v-row
              class="fs-24px fw-semibold mb-8"
              justify="center"
            >
              {{ $t("signInHeader") }}
            </v-row>
            <v-row class="d-flex flex-column">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t("emailOrUsernameLabel") }} <strong class="red--text text--lighten-1">*</strong>
              </v-label>
              <v-text-field
                id="username-field"
                v-model="loginInfo.email"
                :rules="emailOrUsernameValidation"
                :placeholder="$t('inputPlaceholder', { field: $t('emailOrUsernameLabel') })"
                height="38"
                background-color="#F9F9FB"
                class="field-theme"
                :disabled="signinBtnLoading"
              />
            </v-row>
            <v-row class="d-flex flex-column">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t("passwordLabel") }} <strong class="red--text text--lighten-1">*</strong>
              </v-label>
              <v-text-field
                id="password-field"
                v-model="loginInfo.password"
                :placeholder="$t('inputPlaceholder', { field: $t('password') })"
                height="38"
                :rules="passwordValidation"
                background-color="#F9F9FB"
                class="field-theme"
                :type="visiblePassword ? 'text' : 'password'"
                :append-icon="visiblePassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                :disabled="signinBtnLoading"
                @click:append="visiblePassword = !visiblePassword"
              />
            </v-row>
            <div class="row d-flex justify-space-between align-center justify-center">
              <div class="d-flex justify-center align-center">
                <v-checkbox
                  id="remember-me-checkbox"
                  class="field-theme"
                  :ripple="false"
                  off-icon="icon-checkbox-off"
                  on-icon="icon-checkbox-on"
                >
                  <template v-slot:label>
                    <span class="fs-14 text-theme-label">{{ $t("rememberMe") }}</span>
                  </template>
                </v-checkbox>
              </div>
              <router-link
                id="forgot-password-link"
                to="/forgotPassword"
                class="text-decoration-none fw-semibold fs-14"
              >
                {{ $t("forgotPassword") }}
              </router-link>
            </div>
            <v-row class="my-8">
              <v-btn
                id="login-button"
                type="submit"
                block
                color="primary"
                :depressed="true"
                class="btn-theme"
                width="188px"
                height="40"
                :loading="signinBtnLoading"
                :class="{ 'btn-loading-opacity': signinBtnLoading }"
              >
                {{ $t("signIn") }}
              </v-btn>
            </v-row>
            <v-row
              class="text-theme-secondary"
              justify="center"
            >
              {{ $t("doNotHaveAccount") }}
              <router-link
                id="sign-up-link"
                to="/signup"
                class="text-decoration-none fw-semibold fs-14 ml-3"
              >
                {{ $t("signUpTo") }}
              </router-link>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapMutations, mapActions, mapGetters } = createNamespacedHelpers("user");
import makeAuthService from "@/services/api/auth";
import makeUserService from "@/services/api/user";
import { emailOrUsernameValidationRules, requiredFieldValidationRules } from "@/utils/validation";
import { showAuthErrorToast } from '@/utils/toast';

let authService;
let userService;

export default {
  data() {
    return {
      status: "not_accepted",
      loginForm: true,
      visiblePassword: false,
      loginInfo: {
        email: "",
        password: "",
      },
      emailOrUsernameValidation: emailOrUsernameValidationRules(this),
      passwordValidation: requiredFieldValidationRules(this),
      signinBtnLoading: false,
    };
  },
  computed: {
    ...mapGetters(["currentAccount"])
  },
  created() {
    authService = makeAuthService(this.$api);
    userService = makeUserService(this.$api);
  },
  methods: {
    ...mapActions(["initSession"]),
    validate () {
        return this.$refs.form.validate()
      },
      reset () {
        return this.$refs.form.reset()
      },
      resetValidation () {
        return this.$refs.form.resetValidation()
      },
    login() {
      if(this.validate()){
        this.signinBtnLoading = true;
        authService
        .login(this.loginInfo)
        .then(async (response) => {
          const { user } = response.data;
          const orgResponse = await userService.getOrgs(user.uid);
          const { orgs } = orgResponse.data

          let account = {
            handle: user.handle,
            type: "user",
            name: `${user.firstName} ${user.lastName}`,
            roleName: "owner",
            avatarUrl: user.avatarUrl
          };

          if (this.currentAccount) {
            account = orgs.find((a) => a.name === this.currentAccount.name) ?? account;
          }

          this.initSession({ user, currentAccount: account, orgs })

          this.$swal({
            title: this.$t("loginSuccess"),
            icon: "success",
            showConfirmButton: false,
            position: "top-end",
            timer: 2000,
            toast: true,
          });
          const comingFromAuth = ["/login", "/signup", "/setup", "/"].includes(this.$router.history._startLocation);
          const dest = comingFromAuth
            ? `/${this.currentAccount.handle}/projects`
            : this.$router.history._startLocation;
          this.$router.push(dest);
        })
        .catch((res) => {
          showAuthErrorToast(this.$swal, res.response?.data?.error ?? this.$t('problemProcessingRequest'));
        }).finally(() => {
         this.signinBtnLoading = false;
        });
      }
    },
  },
};
</script>
